import axios from "axios";
import {END_POINTS, API_CONFIG} from '../constant/endpoint.js';
let BASE = API_CONFIG.BASE_URL+API_CONFIG.BASE_PATH;
const MY_TEAM_URL = process.env.REACT_APP_MY_TEAM_URL+"/api/v2";
const REPORTS_URL = process.env.REACT_APP_REPORTS_URL+"/api/v2";
let Axios = axios.create({ withCredentials: true, });
const config={
	withCredentials:true,
}
export const auth={
	user:{
		login: res=> Axios.post(END_POINTS.USER.OTP_REQUEST,res,config),
        getUser: params => Axios.get(`${BASE}/user/get`,{params,  ...config}),
        logout: () => Axios.get(`${BASE}/user/logout`, config),
		resendOtpLogin: res=> Axios.post(END_POINTS.USER.RESEND_OTP_REQUEST,res,config),
		submitOtp: res=> Axios.post(END_POINTS.USER.SUBMIT_OTP,res,config),	
        getS3UploadURL: (params) => Axios.put(END_POINTS.USER.GET_FILE_URL, params, config),
        uploadFileToS3: (signed_url, file, headers) => Axios.put(signed_url, file, {...headers, withCredentials: false}),
        updateUser: res => Axios.post(END_POINTS.USER.UPDATEUSER, res, config),
        addDocument: res => Axios.post(END_POINTS.USER.ADD_DOCUMENT, res, config),
        addFile: res => Axios.post(END_POINTS.USER.ADD_FILE, res, config),
        getFile: id => Axios.get(END_POINTS.USER.FETCH_FILE(id), config),
        sendLeadCibilOTP: res=> Axios.post(END_POINTS.USER.SEND_CIBIL_OTP,res,config),
	},
	job:{
		allJob: ()=> Axios.get(END_POINTS.JOB.FETCH_ALL,config),
		jobByid:id=> Axios.get(END_POINTS.JOB.FETCH_JOBAPI(id),config),
        applyJob: res => Axios.post(END_POINTS.JOB.APPLY_JOB, res, config),
        getSlots: params => Axios.get(END_POINTS.JOB.FETCH_SLOT,{params, ...config}),
        getbookedSlot: (params) => Axios.get(END_POINTS.JOB.FETCH_BOOKED_SLOT(params.id, params.job_id), config),
        bookSlot: res => Axios.post(END_POINTS.JOB.BOOK_SLOT, res, config),
	},
    Feedback: {
        fetch: (params)=> Axios.get(END_POINTS.FEEDBACK.FETCH,{params, ...config}),
        fetchResponse: (params)=> Axios.get(END_POINTS.FEEDBACK.FETCH_RESPONSE,{params, ...config}),
        addResponse: (body)=> Axios.post(END_POINTS.FEEDBACK.ADD_RESPONSE,body, config),
    },
    Other: {
        buenoDownload: (body)=> Axios.post(END_POINTS.OTHER.BUENO_DOWNLOAD,body, {...config, responseType: 'arraybuffer' }),
        
    },
    Utm: {
        getS3UploadURL: (params) => Axios.put(`${BASE}/utm/s3/url/signed`, params, config),
        addFile: res => Axios.post(`${BASE}/utm/login`, res, config),

        getCustomerSession: (params)=> Axios.get(`${BASE}/utm/customer/get`,{params, ...config}),
        loginCustomer: body => Axios.post(`${BASE}/utm/login`, body, config),

        fetchUtmOwnerProfile: (params)=> Axios.get(`${BASE}/utm/user/detail`,{params, ...config}),
        addUTMAuth: body => Axios.post(`${BASE}/utm/login`, body, config),
        getTasks: (params, headers)=> Axios.get(`${BASE}/utm/task/details`,{params,  ...config}),
        getTaskForm: (params, headers)=> Axios.get(`${BASE}/utm/task/form/details`,{params,  ...config}),
        addTaskResponse : (body, params={}) => Axios.post(`${BASE}/utm/task/apply`, body, {params, ...config}),
        jobEligibilityCheck : (body, params={}) => Axios.post(`${BASE}/utm/leads/add`, body, {params, ...config}),
    },
    MyTeam: {
        sendOtp: res=> Axios.post(`${MY_TEAM_URL}/otp/send`,res,config),
        sendMyTeamOtp: res=> Axios.post(`${MY_TEAM_URL}/external/user/otp/send`,res,config),
        loginWithOTP: (body) => Axios.post(`${MY_TEAM_URL}/external/user/login/otp`, body, config),
        logout: (body) => Axios.post(`${MY_TEAM_URL}/external/user/logout`, body, config),
        getUser: (params) => Axios.get(`${MY_TEAM_URL}/external/user/get`, {params, ...config}),
        getTeam: (params) => Axios.get(`${MY_TEAM_URL}/external/user/team/detail`, {params, ...config}),

        getS3UploadURL: (params) => Axios.put(`${MY_TEAM_URL}/file/s3/url/signed`, params, config),
        addFile: res => Axios.post(`${MY_TEAM_URL}/file/add`, res, config),
        getFile: id => Axios.get(`${MY_TEAM_URL}/file/fetch?file_id=${id}`, config),
    
        fetchTLUtmLink: (params) => Axios.get(`${MY_TEAM_URL}/external/user/team/task/utm/link`, {params, ...config}),

        fetchProjects: (params) => Axios.get(`${MY_TEAM_URL}/external/user/projects/fetch`, {params, ...config}),
        filterProjectOnPincode: (params) => Axios.get(`${MY_TEAM_URL}/external/user/projects/pincode/filter`, {params, ...config}),
        fetchProjectInfo: (params) => Axios.get(`${MY_TEAM_URL}/external/user/projects/info/fetch`, {params, ...config}),
        fetchProjectReport: (params) => Axios.get(`${MY_TEAM_URL}/external/user/team/projects/data/fetch`, {params, ...config}),
        fetchProjectReportData: (params) => Axios.get(`${MY_TEAM_URL}/external/user/task/response/fetch`, {params, ...config}),
        fetchTaskCount: (params) => Axios.get(`${MY_TEAM_URL}/external/user/team/counts/fetch`, {params, ...config}),
        fetchProjectData: (params) => Axios.get(`${MY_TEAM_URL}/task/fetch/detail`, {params, ...config}),
        submitTaskResponse: (body, headers={}) => Axios.post(`${MY_TEAM_URL}/external/user/task/response/add`, body, {...config, headers}),
        completeTaskResponse: (body, headers={}) => Axios.post(`${MY_TEAM_URL}/external/user/task/response/complete`, body, {...config, headers}),
    },
    Support: {

        getS3UploadURL: (params) => Axios.put(`${BASE}/support/user/s3/url/signed`, params, config),
        addFile: res => Axios.post(`${BASE}/support/user/attachment/add`, res, config),
        getFile: params => Axios.get(`${BASE}/support/user/attachment/fetch`, {params, ...config}),

        fetchUserMetaData: (params) => Axios.get(`${BASE}/profile/metadata/fetch`, {params, ...config}),
        fetchJobs: (params) => Axios.get(`${BASE}/support/user/job/fetch`, {params, ...config}),
        fetchJobFormResponse: (params) => Axios.get(`${BASE}/support/user/task/fetch`, {params, ...config}),
        fetchRechargeData: (params) => Axios.get(`${BASE}/support/user/recharge/fetch`, {params, ...config}),

        fetchTicket: (params) => Axios.get(`${BASE}/support/user/ticket/fetch`, {params, ...config}),
        fetchCreateTicket: (params) => Axios.get(`${BASE}/support/user/ticket/create/fetch`, {params, ...config}),
        fetchChat: (params) => Axios.get(`${BASE}/support/user/ticket/conversation/fetch`, {params, ...config}),
        sendChat: res => Axios.post(`${BASE}/support/user/ticket/chat/add`, res, config),
        markClose: res => Axios.post(`${BASE}/support/user/ticket/update/close`, res, config),
        ticketCallConnect: res => Axios.post(`${BASE}/calling/connect/incoming/ticket`, res, config),
    },
    Partner: {       
        sendOtp: body => Axios.post(`${REPORTS_URL}/otp/send`, body, config),
        loginOtp: body => Axios.post(`${REPORTS_URL}/user/login/otp`, body, config),
        getUser: params => Axios.get(`${REPORTS_URL}/user/get`,{params,  ...config}),
        logout: () => Axios.get(`${REPORTS_URL}/user/logout`, config),

        getS3URL: (params) => Axios.get(`${REPORTS_URL}/file/fetch/with/url`, {params, ...config}),
        getS3UploadURL: (params) => Axios.put(`${REPORTS_URL}/file/s3/url/signed`, params, config),
        addFile: res => Axios.post(`${REPORTS_URL}/file/add`, res, config),

        fetchProjects: params => Axios.get(`${REPORTS_URL}/report/projects/fetch`, {params, ...config}),
        fetchLeadsCount: params => Axios.get(`${REPORTS_URL}/report/task/counts/fetch`, {params, ...config}),
        fetchLeadsData: params => Axios.get(`${REPORTS_URL}/report/task/data/fetch`, {params, ...config}),
        downloadLeadsData: params => Axios.get(`${REPORTS_URL}/report/task/data/download`, {params, ...config}),

        fetchEarnings: params => Axios.get(`${REPORTS_URL}/wallet/amount/fetch`, {params, ...config}),
        fetchEarningLogs: params => Axios.get(`${REPORTS_URL}/wallet/transaction/fetch`, {params, ...config}),

        fetchUserProfile: params => Axios.get(`${REPORTS_URL}/profile/basic/fetch`, {params, ...config}),
        updateUserProfile: body => Axios.post(`${REPORTS_URL}/profile/basic/update`, body, config),

        fetchUserDocuments: params => Axios.get(`${REPORTS_URL}/profile/document/fetch`, {params, ...config}),
        addUserDocument: body => Axios.post(`${REPORTS_URL}/profile/document/add`, body, config),

        fetchUserBank: params => Axios.get(`${REPORTS_URL}/profile/bank/fetch`, {params, ...config}),
        addUserBank: body => Axios.post(`${REPORTS_URL}/profile/bank/add`, body, config),
        setUserBankDefault: body => Axios.post(`${REPORTS_URL}/profile/bank/set/default`, body, config),

        fetchJobs: params => Axios.get(`${REPORTS_URL}/job/user/fetch`, {params, ...config}),

        fetchAssignedCustomerLeads: params => Axios.get(`${REPORTS_URL}/customer/leads/users/fetch`, {params, ...config}),
        fetchCallEnquiry: params => Axios.get(`${REPORTS_URL}/calling/customer/enquiry`, {params, ...config}),
        connectCallCustomer: body => Axios.post(`${REPORTS_URL}/calling/customer/connect`, body, config),

    }
}