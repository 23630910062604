
import React, { Component } from 'react';
import { FormGroup, Input, Label, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import InputFeild from 'reactstrap/lib/Input';
import { ModalBody, ModalHeader, ModalFooter, Modal, Card, CardBody, Collapse } from 'reactstrap';
import { auth as Auth } from '../../../actions/auth';
import CONSTANTS from '../../../constant';
import moment from 'moment';
import { downloadApiFile, uploadPartnerFileToS3 } from '../../../actions';
import { setError, setLoader, setUser } from '../../../actions/action-creator';
import store from '../../../store';

class PartnerDocuments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            panDetails: false,
            firmPanDetails: false,
            aadhaarDetails: false,
            gstDetails: false
        };
    }


  
    componentDidMount() {
        this.fetchDocuments();
    }


    fetchDocuments = async () => {
        let userData = store.getState().user;
        store.dispatch(setLoader(true));
        try {
            let resp = await Auth.Partner.fetchUserDocuments({user_id: userData.user_id});
            let documents = resp.data.data;
            let panDetails = documents.filter(doc => doc.type === "PAN")[0];
            let firmPanDetails = documents.filter(doc => doc.type === "FIRM_PAN")[0];
            let aadhaarDetails = documents.filter(doc => doc.type === "AADHAAR")[0];
            let gstDetails = documents.filter(doc => doc.type === "GST")[0];

            userData.documents = { pan: panDetails && panDetails.number, firmPan: firmPanDetails && firmPanDetails.number, aadhaar: aadhaarDetails && aadhaarDetails.number, gst: gstDetails && gstDetails.number }
            store.dispatch(setUser(userData));

            this.setState({ panDetails: panDetails ? panDetails : false, firmPanDetails: firmPanDetails ? firmPanDetails:false, aadhaarDetails: aadhaarDetails ? aadhaarDetails : false, gstDetails: gstDetails ? gstDetails : false  });
        }
        catch (e) {
            store.dispatch(setError({ showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString() }))
        }
        store.dispatch(setLoader(false));
    }


    addPAN = async (e) => {
        e.preventDefault();
        let userData = store.getState().user;
        store.dispatch(setLoader(true));
        try {
            let body = {
                user_id: userData.user_id,
                type: "PAN",
                number: e.target.number.value
            }
            let resp = await Auth.Partner.addUserDocument(body);
            let panDetails = resp.data.data;
            this.setState({ panDetails });
        }
        catch (e) {
            store.dispatch(setError({ showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString() }))
        }
        store.dispatch(setLoader(false));
    }
    addFirmPAN = async (e) => {
            e.preventDefault();
            let userData = store.getState().user;
            store.dispatch(setLoader(true));
            try {
                let body = {
                    user_id: userData.user_id,
                    type: "FIRM_PAN",
                    number: e.target.number.value
                }
                let resp = await Auth.Partner.addUserDocument(body);
                let firmPanDetails = resp.data.data;
                this.setState({ firmPanDetails });
            }
            catch (e) {
                store.dispatch(setError({ showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString() }))
            }
            store.dispatch(setLoader(false));
        }

    addGST = async (e) => {
        e.preventDefault();
        let userData = store.getState().user;
        store.dispatch(setLoader(true));
        try {
            let body = {
                user_id: userData.user_id,
                type: "GST",
                number: e.target.number.value,
            }
            let fileResp = await uploadPartnerFileToS3(e.target.gst_file.files[0], {file_purpose: "USER_DOCUMENT", user_id: userData.user_id})
            body.file_id = fileResp.file_id

            let resp = await Auth.Partner.addUserDocument(body);
            let gstDetails = resp.data.data;
            this.setState({ gstDetails });
        }
        catch (e) {
            store.dispatch(setError({ showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString() }))
        }
        store.dispatch(setLoader(false));
    } 
    
    addAadhaar = async (e) => {
        e.preventDefault();
        e.persist();
        let userData = store.getState().user;
        store.dispatch(setLoader(true));
        try {
            let body = {
                user_id: userData.user_id,
                type: "AADHAAR",
                number: e.target.number.value,
            }
            let fileRespFront = await uploadPartnerFileToS3(e.target.aadhaar_front.files[0], {file_purpose: "USER_DOCUMENT", user_id: userData.user_id})
            body.file_id = fileRespFront.file_id

            let fileRespBack = await uploadPartnerFileToS3(e.target.aadhaar_back.files[0], {file_purpose: "USER_DOCUMENT", user_id: userData.user_id})
            body.file_id2 = fileRespBack.file_id

            let resp = await Auth.Partner.addUserDocument(body);
            let aadhaarDetails = resp.data.data;
            this.setState({ aadhaarDetails });
        }
        catch (e) {
            store.dispatch(setError({ showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString() }))
        }
        store.dispatch(setLoader(false));
    }




    render() {
        let { } = this.props;
        let { panDetails, firmPanDetails, aadhaarDetails, gstDetails } = this.state;

        return (
        <>
            <Row>
                <Col xs={12}><h5 className='my-3'>Personal Documents</h5></Col>
                <Col xs={12} md={12}>
                    {panDetails ? 
                        <Row>
                            <Col xs={12} md={3}><span>PAN Number</span></Col>
                            <Col xs={12} md={9}><span>{panDetails.number}</span></Col>
                        </Row>
                        :
                        <form onSubmit={this.addPAN}>
                            <Row className="mt-4 align-items-center">
                                <Col xs={12} md={3}><span>PAN No</span></Col>
                                <Col xs={12} md={7}><InputFeild required name="number" placeholder="Enter PAN Number" /></Col>
                                <Col xs={12} md={2} className={"text-right"}><Button>Save</Button></Col>
                            </Row>
                        </form>
                    }
                </Col>
                <Col xs={12} md={12}>
                    {aadhaarDetails ? 
                        <Row>
                            <Col xs={12} md={3}><span>Aadhaar Number</span></Col>
                            <Col xs={12} md={9}><span>{aadhaarDetails.number}</span></Col>
                        </Row>
                        :
                        <form onSubmit={this.addAadhaar}>
                            <Row className="mt-4">
                                <Col xs={12} md={3}><span>Aadhaar No</span></Col>
                                <Col xs={12} md={9}><InputFeild required name="number" placeholder="Enter Aadhaar Number" /></Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} md={3}><span>Aadhaar Front</span></Col>
                                <Col xs={12} md={9}><InputFeild required name="aadhaar_front" type="file" /></Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} md={3}><span>Aadhaar Back</span></Col>
                                <Col xs={12} md={7}><InputFeild required name="aadhaar_back" type="file" /></Col>
                                <Col xs={12} md={2} className={"text-right"}><Button>Save</Button></Col>

                            </Row>
                        </form>
                    }
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="mb-4"><h5 className='mt-5 mb-1'>Business Documents</h5><span className='small d-block' style={{fontSize: 12}}>*Required for users opting for invoicing-based payouts. It is necessary to process monthly payments</span></Col>
                <Col xs={12} md={12}>
                    {firmPanDetails ? 
                        <Row>
                            <Col xs={12} md={3}><span>Business PAN</span></Col>
                            <Col xs={12} md={9}><span>{firmPanDetails.number}</span></Col>
                        </Row>
                        :
                        <form onSubmit={this.addFirmPAN}>
                            <Row className="mt-4 align-items-center">
                                <Col xs={12} md={3}><span>Business PAN</span></Col>
                                <Col xs={12} md={7}><InputFeild required name="number" placeholder="Enter Business PAN Number" /></Col>
                                <Col xs={12} md={2} className={"text-right"}><Button>Save</Button></Col>
                            </Row>
                        </form>
                    }
                </Col>
                <Col xs={12} md={12}>
                    {gstDetails ? 
                        <Row>
                            <Col xs={12} md={3}><span>GST Number</span></Col>
                            <Col xs={12} md={9}><span>{gstDetails.number}</span></Col>
                        </Row>
                        :
                        <form onSubmit={this.addGST}>
                            <Row className="mt-4">
                                <Col xs={12} md={3}><span>GST Number</span></Col>
                                <Col xs={12} md={9}><InputFeild required name="number" placeholder="Enter GST Number" /></Col>
                            </Row>
                            <Row className="mt-4">
                                <Col xs={12} md={3}><span>GST Certificate</span></Col>
                                <Col xs={12} md={5}><InputFeild required name="gst_file" type="file" /></Col>
                                <Col xs={12} md={4} className={"text-right"}><Button>Save</Button></Col>
                            </Row>
                        </form>
                    }
                </Col>
                
            </Row>
        </>
        );
    }
}

export default PartnerDocuments;
