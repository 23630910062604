
import React, { Component } from 'react';
import { FormGroup, Input, Label, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import InputFeild from 'reactstrap/lib/Input';
import { ModalBody, ModalHeader, ModalFooter, Modal, Card, CardBody, Collapse } from 'reactstrap';
import { auth as Auth } from '../../../actions/auth';
import CONSTANTS from '../../../constant';
import moment from 'moment';
import { downloadApiFile, uploadPartnerFileToS3 } from '../../../actions';
import { setError, setLoader } from '../../../actions/action-creator';
import store from '../../../store';
import Axios from 'axios';

class PartnerBankDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bankList: [],
            isAddNewBank: false
        };
    }


  
    componentDidMount() {
        this.fetchBankDetails();
    }


    fetchBankDetails = async () => {
        let userData = store.getState().user;
        store.dispatch(setLoader(true));
        try {
            let resp = await Auth.Partner.fetchUserBank({user_id: userData.user_id});
            let banks = resp.data.data;
            
            this.setState({ bankList: banks });
        }
        catch (e) {
            store.dispatch(setError({ showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString() }))
        }
        store.dispatch(setLoader(false));
    }


    onChangeBankAdd = async (k, v) => {
        let { isAddNewBank } = this.state;
        if(!isAddNewBank) return;
        if(k === "ifsc_code" && (v || "").length === 11){
            store.dispatch(setLoader(true));
            try{
                let ifscResp = await Axios.get(`https://ifsc.razorpay.com/${v}`);
                ifscResp = ifscResp.data;
                isAddNewBank.bank_name = ifscResp.BANK;
                isAddNewBank.branch_detail = ifscResp.ADDRESS;
            } catch(e){
                console.log(e)
            }
            store.dispatch(setLoader(false));
        }
        isAddNewBank[k] = v;
        this.setState({ isAddNewBank })
    }


    saveBankDetails = async () => {
        let userData = store.getState().user;
        store.dispatch(setLoader(true));
        let { bankList, isAddNewBank } = this.state;
        try {
            let body = {
                user_id: userData.user_id,
                ...isAddNewBank
            }
            let resp = await Auth.Partner.addUserBank(body);
            bankList.unshift(resp.data.data);
            
            this.setState({ bankList, isAddNewBank: false });
        }
        catch (e) {
            store.dispatch(setError({ showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString() }))
        }
        store.dispatch(setLoader(false));
    }

    markDefaultBank = async (bank) => {
        let userData = store.getState().user;
        store.dispatch(setLoader(true));
        let { bankList } = this.state;
        try {
            let body = { bank_detail_id: bank.bank_detail_id }
            await Auth.Partner.setUserBankDefault(body);
           this.fetchBankDetails();
        }
        catch (e) {
            store.dispatch(setError({ showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString() }))
        }
        store.dispatch(setLoader(false));
    }

   



    render() {
        let { } = this.props;
        let { bankList, isAddNewBank } = this.state;

        return (

            <>
                {bankList.length < 3 && <Button className="my-4" size="sm" color="primary" onClick={() => this.setState({ isAddNewBank: isAddNewBank? false : {} })}>Add Bank Details</Button>}
                
                <Collapse isOpen={!!isAddNewBank}>
                    <Row>
                        <Col xs={12} md={6}>
                        <form autoComplete="nope">
                            <div className='m-3 shadow-sm p-3'>
                                <InputFeild className="my-2" placeholder="ISFC Code" value={isAddNewBank.ifsc_code || ""} onChange={(e) => this.onChangeBankAdd("ifsc_code", e.target.value) } />
                                {isAddNewBank.bank_name && <span className='d-block small'>{isAddNewBank.bank_name || ""}</span>}
                                {isAddNewBank.branch_detail && <span className='d-block small'>{isAddNewBank.branch_detail || ""}</span>}
                                <InputFeild className="my-2" input autoComplete={"nope"} placeholder="Account Number" type="password"  value={isAddNewBank.account_no || ""} onChange={(e) => this.onChangeBankAdd("account_no", e.target.value) }  />
                                {!!isAddNewBank.account_no && <InputFeild className="my-2" placeholder="Confirm Account Number"  value={isAddNewBank.account_no_confirm || ""} onChange={(e) => this.onChangeBankAdd("account_no_confirm", e.target.value) }  />}
                                {!!isAddNewBank.account_no && !!isAddNewBank.account_no_confirm && isAddNewBank.account_no !== isAddNewBank.account_no_confirm && <span className='text-danger small'>Account number not matched, Please check</span>}
                            </div>
                            <div className='text-right p-3'>
                                <Button type="button" size="sm" color="link" onClick={() => this.setState({ isAddNewBank: false })}>Cancel</Button>
                                <Button type="button" disabled={!isAddNewBank.account_no || isAddNewBank.account_no !== isAddNewBank.account_no_confirm} size="sm" color="primary" onClick={() => this.saveBankDetails()}>Save Details</Button>
                            </div>
                        </form>
                        </Col>
                    </Row>
                </Collapse>
                
                {!bankList.length ? 
                    <Row>
                        <Col xs={12}><em className='small m-5'>No Bank details added</em></Col>
                    </Row>
                    :
                    <Row className='m-3'>
                    {bankList.map(bank => 
                        <Col xs={12} md={6}>
                            <div className='border shadow-sm p-3 m-3'>
                                <Row>
                                    <Col xs={9}>
                                        <span className='d-block  font-weight-bold'>{bank.bank_name} | {bank.ifsc_code}</span>
                                        <span className='d-block'>{bank.account_no}</span>
                                        <span className='d-block small' style={{whiteSpace: "nowrap", overflow: "hidden"}}>{bank.branch_detail}</span>
                                        <span className='small text-secondary'>{moment(bank.created_on).utcOffset("+05:30").format("Do MMM'YY HH:mm")}</span>
                                    </Col>
                                    <Col xs={3} className={"text-right align-self-center"}>
                                        {bank.status === 1 && <span className='small font-weight-bold text-success'>Verified</span>}
                                        {bank.status === 2 && <span className='small font-weight-bold text-danger'>Rejected</span>}
                                        {bank.is_default === 1 && <span className='d-block small text-secondary'>Default</span>}
                                    </Col>
                                </Row>
                                {bank.status ===1 && bank.is_default !== 1 && <Button color="link" size="sm" style={{position: "absolute", right: 30, bottom: 20, fontSize: 12}} onClick={() => this.markDefaultBank(bank)}>Make Default</Button>}
                            </div>
                        </Col>
                    )}
                    </Row>

                }
            </>

        );
    }
}

export default PartnerBankDetails;
