import React, {Component} from 'react';
import { Route, Redirect, Switch } from "react-router-dom";
import { setError, setLoader, setUser } from "../../actions/action-creator";
import store from "../../store";
import {  Row } from 'reactstrap';
import Col from 'reactstrap/lib/Col';
import InputFeild from 'reactstrap/lib/Input';
import { ModalBody, ModalHeader, ModalFooter, Modal ,Card, CardBody } from 'reactstrap';
import { auth as Auth } from '../../actions/auth';
import LeadsReport from './report/leads_report';
import PartnerLogin from './login';
import PartnerSideNav from './sidenav';
import PartnerEarnings from './payout/earnings';
import PartnerProfile from './profile';
import PartnerProjects from './projects';
import PartnerCustomers from './customers';

class Reports extends Component {
   constructor(props) {
     super(props);
     var urlParams = this.props.match.params;
     this.state = {
        userData: false,
        jobList: [],
        error:{showBar:false,message:""},
        isSideNavToggle: false
    };
   }

    componentDidMount(){
        this.changeFavIcon();
        this.checkSession();
    }

    changeFavIcon = () => {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
        }
        if(this.state.isMyTeams) link.href = '/Image/pmw_logo.png';
    }


    checkSession = async () => {
        this.setState({isLoading: true})
        try{
            let userResp = await Auth.Partner.getUser();
            let user = userResp.data.data;
            user.documents = await this.fetchDocuments(user.user_id);
            store.dispatch(setUser(user));
        }
        catch(e){
            store.dispatch(setUser(null));   
            // store.dispatch(setError({showBar: true, message:  e.response && e.response.data ? e.response.data.message : e.toString() }) )
        }
        this.setState({loader:false, isLoading: false})
    }

    fetchDocuments = async (userId) => {
        store.dispatch(setLoader(true));
        try {
            let resp = await Auth.Partner.fetchUserDocuments({user_id: userId});
            let documents = resp.data.data;
            let panDetails = documents.filter(doc => doc.type === "PAN")[0] || {};
            let firmPanDetails = documents.filter(doc => doc.type === "FIRM_PAN")[0]|| {};
            let aadhaarDetails = documents.filter(doc => doc.type === "AADHAAR")[0] || {};
            let gstDetails = documents.filter(doc => doc.type === "GST")[0] || {};

            return { pan: panDetails.number, firmPan: firmPanDetails.number, aadhaar: aadhaarDetails.number, gst: gstDetails.number }
        }
        catch (e) {
            store.dispatch(setError({ showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString() }))
        }
        store.dispatch(setLoader(false));
    }
   
   
   changeTab = (tabId) =>  this.setState({ isSideNavToggle: false });


   render() {
    let { isLoading, isSideNavToggle } = this.state;

    const userData = store.getState().user;

    return (
        <>
            {isLoading ? 
            <h3 className='text-muted d-block w-100 text-center' style={{position: "absolute", top: "calc(50% - 100px)"}}>Loading...</h3>
            : 
            !userData ?  <PartnerLogin checkSession={this.checkSession} />
            :
            <>
                <Row className="shadow py-2 border-bottom">
                    <Col className="">
                        <span className='px-4 font-weight-bold text-secondary' style={{fontSize: 21}}>{userData.first_name}</span>
                    </Col>
                    {window.innerWidth <= 720 && <Col xs={"auto"} className={"px-4"} style={{cursor: "pointer"}} onClick={() => this.setState({ isSideNavToggle: !isSideNavToggle })}><span style={{fontSize: 24}}>&#9776;</span></Col>}
                </Row>
                    

                <Row noGutters className="bg-light">
                {(window.innerWidth > 720 || isSideNavToggle) && 
                    <Col xs={isSideNavToggle ? 9 : 2} className="">
                        <PartnerSideNav />
                    </Col>
                }
                    <Col xs={window.innerWidth > 720 ? 10:12} className="py-1" >
                        <div style={{width: "auto", height: "calc(100vh - 60px)", overflow: "scroll", background: "#fff"}} className="p-3 mx-1 shadow">
                            <Switch>
                                <Route exact path="/partner/profile" default render={props => <PartnerProfile {...props} />} />
                                <Route exact path="/partner/profile/doc" default render={props => <PartnerProfile {...props} selectedTab={1}/>} />
                                <Route exact path="/partner/profile/bank" default render={props => <PartnerProfile {...props} selectedTab={2}/>} />
                                <Route exact path="/partner/projects" default render={() => <PartnerProjects />} />
                                <Route exact path="/partner/report" render={() => <LeadsReport />} />
                                <Route exact path="/partner/earnings" render={() => <PartnerEarnings />} />
                                <Route exact path="/partner/customers" render={() => <PartnerCustomers />} />
                                <Route path="/partner/"  render={() => <Redirect to={userData.first_name ? "/partner/report" : "/partner/profile"} />} />
                            </Switch>
                        </div>
                    </Col>
                </Row>
            </>
            }
             
        </>
    );
 }
}

export default Reports;