
import React, { Component } from 'react';
import { FormGroup, Input, Label, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import InputFeild from 'reactstrap/lib/Input';
import { ModalBody, ModalHeader, ModalFooter, Modal, Card, CardBody, Collapse } from 'reactstrap';
import { auth as Auth } from '../../../actions/auth';
import CONSTANTS from '../../../constant';
import moment from 'moment';
import { downloadApiFile, uploadPartnerFileToS3 } from '../../../actions';
import { setError, setLoader } from '../../../actions/action-creator';
import store from '../../../store';

class PartnerPersonalDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            selectedTab: 0
        };
    }


    componentDidMount() {
      this.fetchProfile();
    }


    fetchProfile = async () => {
        let { } = this.props;
        store.dispatch(setLoader(true));
        try {
            let resp = await Auth.Partner.fetchUserProfile({});
            let userData = resp.data.data || {};
            userData = {...userData, ...(userData.details || {})};
            delete userData.details;
            this.setState({ userData });
        }
        catch (e) {
            store.dispatch(setError({ showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString() }))
        }
        store.dispatch(setLoader(false));
    }

    changeUserData = (k, v) => {
        let { userData } = this.state;
         userData[k] = v;
        this.setState({userData})
    }

    saveDetails = async (e) => {
        e.preventDefault();
        let { userData } = this.state;
        store.dispatch(setLoader(true));
        try {
            let {first_name, email, gender, zip_code, city, state, permanent_address} = userData;
            let updateBody = {
                first_name,
                email,
                gender,
                zip_code,
                city, 
                state,
                permanent_address
            };
            updateBody.location_coords = userData.location_coords || "28.4918475,77.0806481";
            
            if(userData.profile_picture_file){
                let fileResp = await uploadPartnerFileToS3(userData.profile_picture_file, {file_purpose: "USER_PROFILE", user_id: userData.user_id})
                updateBody.profile_picture_key = fileResp.file_id;
            }

            let resp = await Auth.Partner.updateUserProfile(updateBody);
            store.dispatch(setError({ showBar: true, isSuccess: true, message: "Details updated successfully!" }))
        }
        catch (e) {
            store.dispatch(setError({ showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString() }))
        }
        store.dispatch(setLoader(false));
    }

    showProfilePicture = async () => {
        let { userData } = this.state;
        try{
            let body = { file_id: userData.profile_picture_key || 1 }
            let resp = await Auth.Partner.getS3URL(body);
            let fileResp = resp.data.data[0];
            if(fileResp){
                userData.profile_picture_url = fileResp.url;
            }
            this.setState({ userData })
        }
        catch(err){

        }
    }



    render() {
        let { } = this.props;
        let { userData } = this.state;

        return (

            <form onSubmit={this.saveDetails}>
                <Row>
                    <Col xs={12} md={8}>
                        <Row>
                            <Col xs={12} md={2}><span>PMW ID</span></Col>
                            <Col xs={12} md={10}>PMW{userData.id}</Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} md={2}><span>Name</span></Col>
                            <Col xs={12} md={10}><InputFeild value={userData.first_name} onChange={(e) => this.changeUserData("first_name", e.target.value)} placeholder="Enter Name" /></Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} md={2}><span>Email</span></Col>
                            <Col xs={12} md={10}><InputFeild type="email" value={userData.email} onChange={(e) => this.changeUserData("email", e.target.value)} placeholder="Enter Email" /></Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} md={3}><span>Gender</span></Col>
                            <Col xs={12} md={3}>
                                <InputFeild name="gender" type="radio" checked={userData.gender == 1} onChange={(e) => this.changeUserData("gender", 1)} /> Male
                            </Col>
                            <Col xs={12} md={3}>
                                <InputFeild name="gender" type="radio" checked={userData.gender == 2} onChange={(e) => this.changeUserData("gender", 2)} /> Female
                            </Col>
                            <Col xs={12} md={3}>
                                <InputFeild name="gender" type="radio" checked={userData.gender == 3} onChange={(e) => this.changeUserData("gender", 3)} /> Other
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} md={2}><span>Pincode</span></Col>
                            <Col xs={12} md={10}><InputFeild type="number" value={userData.zip_code} onChange={(e) => this.changeUserData("zip_code", e.target.value)} placeholder="Enter Pincode" /></Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} md={2}><span>Location</span></Col>
                            <Col xs={6} md={5}><InputFeild  value={userData.city} onChange={(e) => this.changeUserData("city", e.target.value)} placeholder="Enter City" /></Col>
                            <Col xs={6} md={5}><InputFeild  value={userData.state} onChange={(e) => this.changeUserData("state", e.target.value)} placeholder="Enter State" /></Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} md={2}><span>Full Address</span></Col>
                            <Col xs={12} md={10}><InputFeild value={userData.permanent_address} onChange={(e) => this.changeUserData("permanent_address", e.target.value)} placeholder="Enter your full address" /></Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={4}>
                        <Row>
                            <Col xs={12}><span>Profile Picture/Logo</span></Col>
                            <Col xs={12} className="mt-3">
                                <div className="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm d-flex align-items-center justify-content-between">
                                    <input id="upload" accept=".jpg,.png,.jpeg"  type="file" onChange={(e) => this.changeUserData("profile_picture_file", e.target.files[0])}  className=" d-none form-control border-0" />
                                    <label for="upload" className="font-weight-light text-muted my-0 mx-2">Choose file</label>
                                    <label for="upload" className=" shadow-sm btn btn-light m-0 rounded-pill px-4 text-muted fron-weight-bold small">Choose file</label>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    {(!!userData.profile_picture_file || !!userData.profile_picture_url) && <img src={userData.profile_picture_file ? URL.createObjectURL(userData.profile_picture_file) : userData.profile_picture_url} className='shadow' style={{width: 150, aspectRatio: "1", borderRadius: 200}} />}
                                    {userData.profile_picture_key && !(userData.profile_picture_file || userData.profile_picture_url) && 
                                        <div style={{width: 120, height: 120, borderRadius: "50%", cursor: "pointer"}} className='mt-4 shadow bg-light d-flex align-items-center justify-content-center' onClick={() => this.showProfilePicture()}>
                                            <span className='small font-weight-bold text-muted'>View Photo</span>
                                        </div>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col className={"text-right"}>
                        <Button color="primary" style={{ background: "#4a58d5" }}>Save Details</Button>
                    </Col>
                </Row>
               
            </form>

        );
    }
}

export default PartnerPersonalDetails;
