import React, {Component} from 'react';
import {  Row } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import InputField from 'reactstrap/lib/Input';
import { ModalBody, ModalHeader, ModalFooter, Modal ,Card, CardBody } from 'reactstrap';
import { auth } from '../../actions/auth';
import UserSVG from '../../../src/assets/user.svg';
import JobTask from './job_task';
import JobTaskReport from './job_task_report';
import { copyCodeToClipboard } from '../../actions';
import { TrackEvent } from '../../actions/ga4';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jobsList : [],
            taskCounts: {},
            selectedJob: null, //{job_id: 7},
            isShowReport: false,
            jobSearchText: "",
            jobInfoData: false,
            selectedJobCategoryId: "ALL",
            jobSearchByPincode: ""
        };
    }


    componentDidMount(){
        this.fetchProjects();
        this.fetchTaskCounts();
    }

    fetchProjects = async () => {
        let { setLoader, setError } = this.props;
        setLoader(true);
        try{
            let resp = await auth.MyTeam.fetchProjects();

            let jobsList = resp.data.data;

            jobsList = jobsList.reduce((prev, job) => {
                prev[job.job_category_id] = prev[job.job_category_id] || {jobCategoryData: job.JobCategory, jobsList: []};
                prev[job.job_category_id].jobsList.push(job);
                return prev;
            }, {})
            jobsList = Object.values(jobsList);
            this.setState({ jobsList })
        }
        catch(e){
            setError({showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString()});
        }
        setLoader(false);
    }

    fetchTaskCounts = async () => {
        let { setLoader, setError } = this.props;
        try{
            let resp = await auth.MyTeam.fetchTaskCount();
            let data= resp.data.data[0] && resp.data.data[0].ExternalUsers && resp.data.data[0].ExternalUsers[0] ? resp.data.data[0].ExternalUsers[0].task_count : {}
            this.setState({taskCounts: data})
        }
        catch(e){
            setError({showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString()});
        }
    }

    fetchUserProjectUTMLink = async (jobId) => {
        let { setLoader, setError } = this.props;
        try{
            let body = { entity_type: "JOB", entity_id: jobId }
            let resp = await auth.MyTeam.fetchTLUtmLink(body);
            return resp.data.data.utm_link;
        }
        catch(e){
            setError({showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString()});
        }
    }

    shareTaskLink = async (job) => {
        let { setLoader, setError } = this.props;

        setLoader(true);

        let body = { job_id: job.job_id }
        let resp = await auth.MyTeam.fetchProjectData(body);
        let taskData = resp.data.data[0];
        let link = "";
        if(taskData.formData.description && taskData.formData.description.data && taskData.formData.description.data.length){
            link =  taskData.formData.description.data[0].data;
        } else {
            link = await this.fetchUserProjectUTMLink(job.job_id);
        }
        link = (link || "").replace("pickmywork.com", "abbecho.com");
        let shareData = {url: link, title: "Share Project Link"};
        if (navigator.share && navigator.canShare(shareData)) {
            try {
                await navigator.share(shareData)
              } catch (error) {
                console.log(`Oops! I couldn't share to the world because: ${error}`);
              }
        } else {
            copyCodeToClipboard(link);
            setError({showBar: true, isSuccess: true, message: "Link Copied!"});
        }
        setLoader(false);
    }

    shareImage = async (event, url) => {
        event.preventDefault();
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const filesArray = [new File([blob], 'image.jpg', { type: blob.type })];
                const shareData = {
                    files: filesArray,
                    title: "Marketing Banner"
                };
                if (navigator.share) {
                    navigator.share(shareData)
                    .then(() => console.log('Image shared successfully'))
                    .catch(error => console.error('Error sharing image:', error));
                } else {
                    console.log('Web Share API not supported.');
                }
        })
        .catch(error => console.error('Error fetching image:', error));
    }

    handleChromeAppInstall = () =>  {
        if(window.checkInstallBrowserApp){
            TrackEvent("MyTea_Web_App_Prompt", {})
            window.checkInstallBrowserApp.prompt();
            window.checkInstallBrowserApp.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    TrackEvent("MyTea_Web_App_Install", {});
                }
                window.checkInstallBrowserApp = null;
                this.forceUpdate();
            });
        }
    }

    showJobInfo = async (jobData) => {
        let { setLoader, setError } = this.props;
        setLoader(true);
        try{
            let body = { job_id: jobData.job_id }
            let resp = await auth.MyTeam.fetchProjectInfo(body);
            this.setState({ jobInfoData: {...jobData, ...resp.data.data} });
        }
        catch(e){
            setError({showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString()});
        }
        
        setLoader(false);
    }

    searchByPincode = async (pincodeValue="") => {
        let { setLoader, setError } = this.props;
        if(pincodeValue.length >= 6){
            pincodeValue = pincodeValue.substring(0, 6);
            
            try{
                setLoader(true);

                let { jobsList } = this.state;
                let jobIds = [];
                jobsList.map(catData => {
                    catData.jobsList.forEach(job => { jobIds.push(job.job_id) });
                });
                let body = { job_id: jobIds, pincode: pincodeValue }
                let resp = await auth.MyTeam.filterProjectOnPincode(body);
                let filteredJobIds = (resp.data.data || []).map(Number);

                jobsList = jobsList.map(catData => {
                    catData.jobsList = catData.jobsList.map(job => {
                        if(filteredJobIds.includes(Number(job.job_id))) job.isSearchPincodeMatch = true;
                        else job.isSearchPincodeMatch = false;
                        return job;
                    });
                    return catData;
                })

                this.setState({ jobsList });

                TrackEvent("MyTeam_Pincode_Search", { pincode: pincodeValue, filteredJobCounts: filteredJobIds.length })

            }
            catch(e){
                setError({showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString()});
            }
            setLoader(false);
        }
        this.setState({ jobSearchByPincode: pincodeValue })
    }


    render() {
        let { jobsList, taskCounts, selectedJob, isShowReport, jobSearchText, jobInfoData, selectedJobCategoryId } = this.state;
        let { teamData, userData } = this.props;
        return (
        isShowReport ? <JobTaskReport {...this.props} onClose={()=> this.setState({isShowReport: false})} />
        :
        selectedJob ? 
            <JobTask {...this.props} selectedJob={selectedJob} onClose={() => this.setState({selectedJob: null})} refreshCount={() => this.fetchTaskCounts()} />
            : 
            <>
                <div className='position-relative text-left'>
                    <div className='position-absolute' style={{height: 160, width: "100%", background: "#eaf2f1", zIndex: "-1"}}></div>
                    <div className='p-3'>
                        <Row className="align-items-center">
                            <Col xs={2}><img src={teamData && teamData.profile_picture_url ? teamData.profile_picture_url : UserSVG} className='' style={{width: 50, height: 50, borderRadius: "50%", border: "3px solid #fff" }} /> </Col>
                            <Col className=" text-left">
                                <strong className='d-block' style={{color: "#080808", fontSize: 18}}>{ userData.first_name }</strong>
                                <span className='p-1' style={{background: "#699d9c", color: "#fff", borderRadius: 6, fontSize: 10}}>{teamData ? teamData.User.first_name : "Team Manager"}</span>
                            </Col>
                            <Col xs={"auto"} className="text-right">
                                <div className='' >
                                    {window.checkInstallBrowserApp && <Button color="link" className="p-0" onClick={this.handleChromeAppInstall} ><img width={35} src='/Image/Install.svg' /></Button>}
                                    <Button color="link" className="p-0 ml-2" onClick={this.props.logout} ><img width={35} src='/Image/Logout.svg' /></Button>
                                </div>
                            </Col>
                        </Row>


                        <div className='shadow-sm mt-2  bg-light p-3' style={{borderRadius: 12}}>
                            <Row className="border-bottom align-items-center pb-2">
                                <Col>
                                    <strong className='d-block'>My Lead's Report</strong>
                                </Col>
                                <Col xs="auto" className="text-right">
                                    <Button size="sm" className="py-1" outline={true} style={{borderRadius: 16, fontSize: 12}} onClick={() => this.setState({isShowReport: true})}>View All Leads</Button>
                                </Col>
                            </Row>

                            <Row className="mt-2 text-center">
                                <Col xs={3} className="">
                                     <strong className='d-block text-muted' style={{fontSize: 12}}>Added</strong>
                                     <span className="d-block mt-1">{(taskCounts.pending || 0)}</span>
                                </Col>
                                <Col xs={3} className="">
                                    <strong className='d-block text-muted' style={{fontSize: 12}}>Verified</strong>
                                    <span className="d-block mt-1">{(taskCounts.verified || 0)}</span>
                                </Col>
                                <Col xs={3} className="">
                                    <strong className='d-block text-muted' style={{fontSize: 12}}>Rejected</strong>
                                    <span className="d-block mt-1">{(taskCounts.rejected || 0)}</span>
                                </Col>
                                <Col xs={3} className="">
                                    <strong className='d-block text-muted' style={{fontSize: 12}}>Expired</strong>
                                    <span className="d-block mt-1">{(taskCounts.archived || 0)}</span>
                                </Col>
                            </Row>                         
                        </div>
                        {jobsList.length > 1 && <div className='w-100 mt-3' style={{overflowX: "auto", whiteSpace: "nowrap", maxWidth: "100%", scrollbarWidth: "none"}}>
                        {
                            [{jobCategoryData: {title: "All", job_category_id: "ALL"}}, ...jobsList].map(obj => 
                            <span key={obj.job_category_id} className='px-2 py-1  border mr-2 d-inline-block' style={{background: selectedJobCategoryId === obj.jobCategoryData.job_category_id ? "#699d9c" : "#eaf2f1", color: selectedJobCategoryId === obj.jobCategoryData.job_category_id ? "#fff":"#666", borderRadius: 16, cursor: "pointer", fontSize: 11, fontWeight: 600}} onClick={() => this.setState({ selectedJobCategoryId: obj.jobCategoryData.job_category_id })}>
                                {obj.jobCategoryData.title}
                            </span>)
                        }
                        </div>}

                        <Row noGutters className="mt-3">
                            <Col className="pr-2">
                                <InputField className="small" style={{borderRadius: 14}} placeholder="Search by Name" onChange={(e) => this.setState({ jobSearchText: e.target.value })} />
                            </Col>
                            <Col>
                                <InputField type="number" className="small" style={{borderRadius: 14}} value={this.state.jobSearchByPincode} placeholder="Search by Pincode" onChange={(e) => this.searchByPincode(e.target.value)} />
                            </Col>
                        </Row>
                        {!!this.state.jobSearchByPincode && this.state.jobSearchByPincode.length === 6 && <em className='small text-muted'>Projects showing for pincode {this.state.jobSearchByPincode}</em>}
                        
                        {jobsList.map(categoryData => {
                            if(selectedJobCategoryId !== "ALL" && selectedJobCategoryId !== categoryData.jobCategoryData.job_category_id) return <></>;
                            let categoryJobList = categoryData.jobsList.filter(job => jobSearchText ? (job.Employer.employer_name + job.title + job.JobCategory.title).toLowerCase().includes(jobSearchText.toLowerCase().trim()) : true);
                            if(this.state.jobSearchByPincode && this.state.jobSearchByPincode.length === 6) categoryJobList = categoryJobList.filter(job => !!job.isSearchPincodeMatch);
                            return <div>
                                {categoryJobList.length > 0 && <span className='d-block mt-3 small font-weight-bold text-secondary'>{categoryData.jobCategoryData.title}</span>}
                                {categoryJobList.map(job => 
                                <div className='shadow-sm my-2 bg-light p-3' style={{borderRadius: 12, position: "relative"}}>
                                        {!!job.application_archive_status && !!job.application_archive_message && 
                                            <Row>
                                                <Col className="text-left"><strong className='text-danger small'><em>Hold: {job.application_archive_message}</em></strong></Col>
                                            </Row>
                                        }
                                    <Row className="align-items-center mt-1">
                                        <Col xs={2}><img src={job.logo_key} className='' style={{width: 50, height: 50, borderRadius: "50%", border: "3px solid #fff" }} /> </Col>
                                        <Col xs={7} className="">
                                            <strong className='d-block' style={{color: "#080808", fontSize: 16}}>{job.Employer.employer_name} - {job.title}</strong>
                                            <span className='small text-secondary'>{job.JobCategory.title}</span>
                                        </Col>
                                        <Col xs={3} className="text-right">
                                            <Button disabled={!!job.archive_status || !!job.application_archive_status} size="sm border-0 w-100" onClick={() => job.action_status == 20 ? this.shareTaskLink(job) : this.setState({selectedJob: job})} style={{borderRadius: 16, background: "#fac240", color: "#000", fontWeight: 600, fontSize: 13}}>{job.action_status == 20 ? "Share" : "Add"}</Button>
                                        </Col>
                                        {!!job.archive_status && !!job.archive_message && 
                                            <Col xs={12} className="text-right"><strong className='text-danger small'><em>{job.archive_message}</em></strong></Col>
                                        }


                                        {
                                            job.Features && !!job.Features.length && 
                                            <Col xs={12}>
                                                <ul className='pl-2 mt-2 mb-1'>
                                                    {job.Features.map(feature => <li className='small'>{feature}</li>)}
                                                </ul>
                                            </Col>
                                        }
                                        
                                        <Button color="link" size="sm" className="" style={{fontSize: 11, fontWeight: 700, color: "#387c7b", position: "absolute", bottom: 3, right: 3}} onClick={() => this.showJobInfo(job)}>More Details</Button>
                                    </Row>
                                </div>
                                )}
                            </div>
                        })}
                    </div>
                        
                    <Modal centered isOpen={!!jobInfoData} toggle={() => this.setState({jobInfoData: false})} style={{  alignItems: "end", width: "100%", margin: 0}} >
                        {jobInfoData && <ModalBody className="py-0 px-2" style={{ maxHeight: "90vh", overflowY: "auto"}}>
                            <Row noGutters className="bg-light py-1 align-items-center" style={{position: "sticky", top: 0, zIndex: 99}}>
                                <Col xs={2}><img src={jobInfoData.logo_key} className='' style={{width: 50, height: 50, borderRadius: "50%", border: "3px solid #fff" }} /> </Col>
                                <Col className="">
                                    <strong className='d-block' style={{color: "#080808", fontSize: 16}}>{jobInfoData.Employer.employer_name} - {jobInfoData.title}</strong>
                                    <span className='small text-secondary'>{jobInfoData.JobCategory.title}</span>
                                </Col>
                                <Col xs="auto" className={"px-2"} style={{fontSize: 22, cursor: "pointer"}} onClick={() => this.setState({jobInfoData: false})}>&#10006;</Col>
                            </Row>

                            <div className='text-left'>
                                <div className='small mt-1' dangerouslySetInnerHTML={{__html: jobInfoData.DESCRIPTION || ""}}></div>
                                
                                <span className='text-secondary font-weight-bold' style={{fontSize: 14}}>Benefits to Customer</span>
                                {jobInfoData.CRITERIA && <Row className="align-items-center my-2 mx-2 p-1" style={{background: "#e9f2f1", borderRadius: 10}}>  
                                    {
                                        (jobInfoData.CRITERIA || "").split(";;").map(criteriaData => {
                                            let criteria = (criteriaData || "").split("_");
                                            return <Col className={"text-center"}>
                                                    <span className='' style={{ fontSize: 12 }}>{criteria[0]}</span>
                                                    <span className='d-block small' style={{ fontWeight: 700 }}>{criteria[1]}</span>
                                            </Col>
                                        })
                                    }
                                </Row>}
                                <div className='small job-info-list-style' dangerouslySetInnerHTML={{__html: jobInfoData.FEATURES || ""}}></div>
                                
                                <span className='text-secondary font-weight-bold' style={{fontSize: 14}}>Customer Eligibility</span>
                                <div className='small job-info-list-style'  dangerouslySetInnerHTML={{__html: jobInfoData.TNC || ""}}></div>

                                <Row className="align-items-center w-100 mt-3 mb-4" style={{ overflowX: "scroll", flexWrap: "nowrap" }}>
                                    {
                                        (jobInfoData.MARKETING_MATERIAL || []).map(bannerUrl => 
                                            <Col xs={5}><img onClick={(e) => this.shareImage(e, bannerUrl)} style={{width: "100%"}} src={bannerUrl} /></Col>
                                        )
                                    }
                                </Row>
                            </div>
                           
                        </ModalBody>}
                    </Modal>
                </div>
            </>
                
        );
        }
}

export default Dashboard;
